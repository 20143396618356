<template>
  <div class="tableTent">
    <div class="setting-nav-title clearfix">
      {{ $t("tableTent") }}
      <rating-popover
        class="pull-right"
        :defaultVisible="true"
        rateTitle="機能改善、サービス品質向上のためにアンケートにご協力お願いします。"
        :rateData="[
          {
            title:
              '・利用申込から利用開始までの期間について満足度を星1つ（かなり不満）から星5つ（大満足）の範囲で教えてください。',
            type: 'star',
            platform: 'StarPay-Works',
            entCode: $store.state.app.userInfo.entName,
            entName: $store.state.app.userInfo.entDisplayName,
            userName: $store.state.app.userInfo.userName,
            oem: $settings.oem
          }
        ]"
        placement="bottom"
        :showFrequency="userInfo.isMasterEnterprise ? 'never' : $settings.oem == 'netstars' ? 'once' : 'never'"
      >
        <el-button v-if="$settings.enableGaia === true" class="confirm pull-right" @click="addPayment()">
          {{ $t("addPayment") }}
        </el-button>
      </rating-popover>
      <el-button
        class="download cancel pull-right"
        icon="el-icon-download"
        style="margin-right:10px"
        :loading="btnLoading"
        @click="downloadTableTent()"
      >
        {{ $t("downloadTableTent") }}
      </el-button>
    </div>

    <div class="tableTentContent">
      <div class="paymentBorder">
        <div :class="`paymentGroup ${$settings.oem}`">
          <div class="creditTouch" v-if="creditTouch.length > 0">
            <template v-for="item in creditTouch">
              <el-image class="image" v-if="item.order < 5" :src="item.imagePath"></el-image>
            </template>
          </div>
          <div class="creditNoTouch" v-if="creditNoTouch.length > 0">
            <template v-for="item in creditNoTouch">
              <el-image class="image" v-if="item.order < 5" :src="item.imagePath"></el-image>
            </template>
          </div>
          <div class="ICGroup" v-if="IC.length > 0">
            <div class="IC">
              <template v-for="item in IC">
                <el-image class="image" v-if="item.order < 10" :src="item.imagePath"></el-image>
              </template>
            </div>
            <div class="FC">
              <template v-for="item in FC">
                <el-image class="image" v-if="item.order < 4" :src="item.imagePath"></el-image>
              </template>
            </div>
          </div>
          <template v-else>
            <div class="FCELSE">
              <template v-for="item in FC">
                <el-image class="image" v-if="item.order < 4" :src="item.imagePath"></el-image>
              </template>
            </div>
          </template>
          <div class="" v-if="QR.length > 0">
            <!-- <div class="qrcontainer" v-for="(item, index) in Array(30)" @drop="handleDrop()" @dragover="handleDrop()">
            <div v-if="index + 1 <= QR.length" draggable="true" @drag="handleDrag()" @drogover.prevent>
              <el-image class="image" :src="QR[index].imagePath" :alt="QR[index].paymentType"></el-image>
            </div>
          </div> -->

            <draggable
              class="QR"
              tag="div"
              v-model="QR"
              @change="drag()"
              v-bind="{
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
              }"
            >
              <div class="qrcontainer" v-for="item in QR" :key="item.order">
                <el-image class="image" :src="item.imagePath" :alt="item.paymentType"></el-image>
              </div>
            </draggable>
          </div>
          <div class="POINT" v-if="POINT.length > 0">
            <template v-for="item in POINT">
              <el-image class="image" v-if="item.order < 4" :src="item.imagePath"></el-image>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDisplaycard, downloadDisplaycard, postDisplaycard } from "api/tableTent";
import { executeDownload } from "utils/download";
import Draggable from "vuedraggable";
import { getSessionStorage } from "@/utils/storage";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      dragLoading: false,
      paymentData: [],
      creditTouch: [],
      creditNoTouch: [],
      IC: [],
      FC: [],
      POINT: [],
      QR: [],
      userInfo: {}
    };
  },
  components: {
    Draggable
  },
  methods: {
    drag(e) {
      // console.log(e);
      console.log("drag");
      let arr = new Array();
      //排序
      for (let i in this.QR) {
        this.QR[i].order = i * 1 + 1;
        arr.push(this.QR[i]);
      }
      //组合数据
      let postArr = arr.concat(this.creditTouch, this.creditNoTouch, this.IC, this.FC, this.POINT);
      console.log(postArr);

      postDisplaycard({ customDisplayCardData: postArr }).then(res => {
        console.log(res);
        if (res.statusCode == 200) {
          this.$message.success(this.$t("success"));
        }
      });
      // this.dragLoading = true;
    },
    addPayment() {
      this.$confirm(this.$t("ttips.addPayment"), "", {
        showConfirmButton: this.$settings.gaiaUrl,
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "info"
      })
        .then(() => {
          window.open(this.$settings.gaiaUrl);
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    downloadTableTent() {
      this.btnLoading = true;
      downloadDisplaycard().then(response => {
        // console.log(res);
        this.btnLoading = true;
        if (response.data) {
          executeDownload(response);
          this.btnLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnLoading = false;
        }
      });
    }
  },

  computed: {},

  mounted() {
    getDisplaycard().then(res => {
      console.log(res);
      if (res.statusCode == 200) {
        this.paymentData = res.result;
        this.creditTouch = res.result.filter(e => {
          return e.category === "CreditTouch";
        });
        this.creditNoTouch = res.result.filter(e => {
          return e.category === "CreditNoTouch";
        });
        this.IC = res.result.filter(e => {
          return e.category === "IC";
        });
        this.FC = res.result.filter(e => {
          return e.category === "FC";
        });
        this.POINT = res.result.filter(e => {
          return e.category === "POINT";
        });

        //倒腾数据
        let tempQRJSON = {};
        let tempQR = res.result.filter(e => {
          return e.category === "QR";
        });

        for (let i in tempQR) {
          tempQRJSON[tempQR[i].order] = tempQR[i];
        }

        console.log(tempQRJSON);
        for (let j in tempQRJSON) {
          this.QR.push(tempQRJSON[j]);
        }
        //mock
        // this.IC = res.result.filter(e => {
        //   return e.category === "QR";
        // });
      }
    });

    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
  }
};
</script>
<style scoped>
/* .ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
  list-style: none;
}
.list-group-item {
  cursor: move;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ccc;
} */
</style>
