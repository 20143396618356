import http from "../http";

export const getDisplaycard = params => {
  return http({
    method: "get",
    url: "/api/paymentdisplaycard",
    params
  });
};

export const downloadDisplaycard = params => {
  return http({
    method: "get",
    url: "/api/paymentdisplaycard/download",
    params,
    responseType: "blob"
  });
};

export const postDisplaycard = data => {
  return http({
    method: "post",
    url: `/api/paymentdisplaycard/custom`,
    data: data
  });
};
///api/paymentdisplaycard/custom
